import React from 'react';

export const ItemHeader = () => {
	return (
		<form>
			<div className='row itemHeaderContainer'>
				<div className='col itemHeader'>Item</div>
				<div className='col-1 itemHeader'>Prijs</div>
				<div className='col-1 itemHeader'>Aantal</div>
				<div className='col-1 itemHeader'>Totaal</div>
			</div>
		</form>
	);
};
