import React from 'react';

export const Item = () => {
	return (
		<form>
			<div className='row item'>
				<div className='col itemText'>
					<span className='col itemText'>Item</span>
					<br />
					<span className='col itemDescription'>description</span>
				</div>
				<div className='col-1 itemPrice'>
					<input type='number' min='0' />
				</div>
				<div className='col-1 itemNbrPurchased'>
					<input type='number' min='0' />
				</div>
				<div className='col-1 itemTotal'>Totaal</div>
			</div>
		</form>
	);
};
