import React from 'react';
import { ReceivedAmount } from './ReceivedAmount';
import { TotalAmount } from './TotalAmount';
import { Image, Button } from 'react-bootstrap';

export const Header = () => {
	return (
		<div className='container-fluid header'>
			<div className='row justify-content-center'>
				<div className='col-2 text-center logo'>
					<Image
						className='rounded'
						src='./assets/funbaselogo.png'
						alt='logo'
						style={{ margin: '10px', maxWidth: '150px' }}></Image>
				</div>{' '}
				<div className='col title'></div>
				<div className='col-3 totalen'>
					<TotalAmount />
					<ReceivedAmount />
				</div>
			</div>
		</div>
	);
};
