import React from 'react';

export const ReceivedAmount = () => {
	return (
		<div className='received'>
			<h4>Ontvangen</h4>
			<h5>Terug</h5>
		</div>
	);
};
