import React from 'react';
import { Item } from './Item';
import { ItemHeader } from './ItemHeader';

export const ItemsList = () => {
	return (
		<div className='itemsList'>
			<ItemHeader />
			<Item />
			<Item />
			<Item />
			<Item />
		</div>
	);
};
